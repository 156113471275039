@import '_Constants.scss';
@import '_MCR_Constants.scss';

.hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  // max-width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  
  &.top-hero {
    background-image: url(../../assets/images/campaign-image.png);
    height: 752px;
  }
  &.bottom-hero {
    background-image: url(../../assets/images/campaign-image2.png);
    height: 554px;
  }
  
  .hero-content {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: $White;
    .v3-header {
      color: $White;
    }

    .hero-super-header {
      text-transform: uppercase;
      letter-spacing: 1.8px;
    }

    .hero-header {
      margin-top: 16px;
      font-size: 60px;
      line-height: 72px;
      max-width: 789px;
    }

    .hero-sub-header {
      margin-top: 16px;
    }

    button {
      width: 140px;
      margin-top: 64px;
      height: 40px;
      padding: 0;
      a {
        color: unset;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    .activate {
      margin-top: 16px;
    }

    .provided-by {
      margin-top: 104px;
      display: flex;
      align-items: center;
      div {
        font-family: MarkForMC;
        margin-right: 16px;
        font-weight: 350;
      }
    }
  }
}

.hero-2-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 268px;
  .message {
    // color: $MCRBlack;
    max-width: 1140px;
    font-size: 32px;
    line-height: 54.4px;
    text-align: center;
  }
}

.why-choose-block {
  background-color: $White;
  padding: 80px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .subtitle {
    text-transform: uppercase;
    color: $RoyalBlue;
    font-weight: 700;
    letter-spacing: 1.8px;
  }
  .title {
    margin-top: 16px;
    font-size: 36px;
    line-height: 42px;
    font-weight: 400;
  }
  .reason-container {
    margin-top: 60px;
    max-width: 1280px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 70px;

    .reason-item {
      display: flex;
      flex-direction: column;
      img {
        height: 80px;
        width: 80px;
      }
      h4 {
        margin-top: 27px;
        font-size: 28px;
        line-height: 36px;
        font-weight: 400;
      }
      .reason-desc {
        margin-top: 4px;
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}

.comprehensive-approach-block {
  padding: 80px 20px;
  display: flex;
  flex-direction: column;

  align-items: center;
  
  .blocks-container {
    max-width: 1140px;

    .top-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 17px;
  
      .determining-rating {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-right: 72px;
  
        .comprehensive-title {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 1.8px;
          line-height: 16px;
        }
  
        h4 {
          margin-top: 16px;
          font-size: 36px;
          line-height: 42px;
          font-weight: 400;
        }

        .comprehensive-description {
          margin-top: 40px;
          font-size: 20px;
          line-height: 32px;
          font-weight: 400;
        }
      }

      .screenshot-container {
        padding-top: 72px;
        img {
          width: 100%;
          max-width: 571px;
          top: 393px;
          border-radius: 4px;
          box-shadow: 0px 2px 13px 0px $NileBlueA25, 4px 1px 20px 20px $NileBlueA05;
        }
      }
    }
    .bottom-block {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 40px 1fr 40px 1fr;
      column-gap: 10px;
      height: 520px;
      .bottom-item-container {
        img {
          height: 40px;
          width: 40px;
        }
        h5 {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
  
        .bottom-item-descs {
          margin-top: 4px;
          font-size: 16px;
          font-weight: 400;
          line-height: 25.6px;
        }

        .two-col-list {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }

      .connector-one {
        margin-top: 110px;
      }

      .connector-two {
        margin-top: 260px;
      }
      
      .security-assessments {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .infrastructure-assessments {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .impact-analysis {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
}

